<template>
	<div class="page-content">
		<div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<!--<el-form-item label="分类名称" prop="username">-->
					<!--<el-input v-model="searchForm.name" style="width: 220px;" clearable-->
							  <!--placeholder="请填写分类名称"></el-input>-->
				<!--</el-form-item>-->
				<!--<el-form-item style="margin-top: auto;margin-right: auto;">-->
					<!--<div class="fill-btn" @click="getMessageType">查询</div>-->
				<!--</el-form-item>-->
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe
					  v-loading="loading"
					  @selection-change="handleSelectionChange"
					  cell-class-name="text-center"
					  header-cell-class-name="header-row-class-name">
				<el-table-column prop="banner" label="图片" min-width="20%">
					<!-- 图片的显示 -->
					<template slot-scope="{row}">
						<img :src="row.banner" min-width="70" height="70"/>
					</template>
				</el-table-column>

				<el-table-column width="200" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
									   @click="handEditShow(row)"></el-button>
							<el-button class="el-icon-delete cur-p" type="primary" size="mini"
							@click="delUuserQA(row)"></el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination
						background
						:current-page.sync="searchForm.page"
						:page-size="searchForm.limit"
						@current-change="searchForm.page=$event"
						@size-change="handleSizeChange"
						layout="total, prev, pager, next, jumper"
						:total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="添加轮播"    :visible.sync="showAdd" >
			<div style="padding: 20px;">
				<el-form ref="form" :model="addTeacher" label-width="80px">
					<el-form-item label="轮播">
						<el-upload
								class="avatar-uploader"
								action="/admin/upload"
								:show-file-list="false"
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
							<img v-if="addTeacher.banner" :src="addTeacher.banner" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div class="el-upload__tip" slot="tip">351*160</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="跳转类型">
						<el-select v-model="addTeacher.key"   @change="changeKey(addTeacher.key)"  placeholder="请选择">
							<el-option
									v-for="item in roleOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item  v-if="addTeacher.key < 3"  label="选择">
						<el-select :loading="typeLoading" v-model="addTeacher.value"   placeholder="请选择">
							<el-option
									v-for="item in typeList"
									:key="item.id"
									:label="item.name"
									:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-else-if="addTeacher.key == 4" label="选择链接">
						<el-select :loading="typeLoading" v-model="addTeacher.value"  placeholder="请选择">
							<el-option
									v-for="item in articles"
									:key="item.id"
									:label="item.title"
									:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="addUserQA" style="margin: 10px auto">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="轮播修改"    :visible.sync="showEdit" >
			<div style="padding: 20px;">
				<el-form ref="form" :model="editForm" label-width="80px">
					<el-form-item label="轮播">
						<el-upload
								class="avatar-uploader"
								action="/admin/upload"
								:show-file-list="false"
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
							<img v-if="editForm.banner" :src="editForm.banner" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div class="el-upload__tip" slot="tip">351*160</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="跳转类型">
						<el-select v-model="editForm.key"  @change="changeKey(editForm.key)"  placeholder="请选择">
							<el-option
									v-for="item in roleOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="editForm.key < 3" label="选择链接">
						<el-select :loading="typeLoading" v-model="editForm.value"  placeholder="请选择">
							<el-option
									v-for="item in typeList"
									:key="item.id"
									:label="item.name"
									:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-else-if="editForm.key == 4" label="选择链接">
						<el-select remote reserve-keyword :remote-method="remoteMethod" :loading="typeLoading" v-model="editForm.value"  placeholder="请选择">
							<el-option
									v-for="item in typeObj"
									:key="item.id"
									:label="item.title"
									:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="saveUserQA()" style="margin: 10px auto">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {delToast, formVerify} from './../../utlis/decorators'
	import RichText from './../../components/RichText'

	export default {
		name: "tenant_account",
		props: {
			parkId: {
				type: [Number, String],
				default: null
			},
		},
		components: {
			RichText
		},
		data() {
			return {
				dialogFormVisible:false,
				loading: false,
				currentTop: 0,
				total: 0,
				showAdd: false,
				showEdit: false,
				showEditPsd: false,
				editing: false,
				searchForm: {
					page: 1,
					limit: 10,
					username: ''
				},
				form: {
					nickname: ''
				},
				typeList:[],
				editForm: {
					value: '',
					key:'',
					banner:''
				},
				currentId: '',
				tableData: [],
				addTeacher:{
					banner:'',
					type:'',
				},
				roleOptions: [
					{value: 1, label: '主题'},
					{value: 2, label: '店铺'},
					{value: 3, label: '代金券'},
					{value: 4, label: '文章'},
					{value: 5, label: '积分商城'},
				],
				typeLoading:false,
				articles:[],
				oldSelect:[],
			}
		},
		created() {
			this.getArticleAll();
			this.getMessageType();
		},
		computed: {
			getRules() {
				return {
					role_ids: [{required: true, message: `角色不能为空`, trigger: ['blur', 'change']}],
					nickname: [{required: true, message: `昵称不能为空`, trigger: ['blur', 'change']}],

				}
			},
			typeObj(){
				return this.articles.reduce((obj,item)=>{
					obj[item.id] = item
					return obj
				},{})
			}
		},
		watch: {
			'searchForm.page'() {
				this.getMessageType();
			},
		},
		methods: {
			//头像
			handleAvatarSuccess(res, file) {

				this.editForm.banner = res.url;
				this.addTeacher.banner = res.url;
				this.$forceUpdate();
				console.log( this.addTeacher.banner );
			},

			beforeAvatarUpload(file) {
				const isJPG = file.type === 'jpg/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG,png 格式!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isLt2M;
			},
			handShowAdd() {
				this.editForm = [];
				this.addTeacher = {
					banner:'',
					type:'',
					value:'',
				};
				this.showAdd = true;
			},
			handEditShow(row) {
				this.editForm = row;
				this.typeLoading = true;
				this.$set(this.editForm,'value',Number(this.editForm.value))
				if(row.key>3){
					
					this.getArticleAll('',row.value)
				}else if(row.key<3){
					this.getTypeList(row.key,1)
				}else{
					this.showEdit = true;
				}
			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			handleSelectionChange(val) {
				this.selectData = val;
			},
			//获取分类列表
			async getMessageType() {

				let [e, data] = await this.$api.getBanner(this.searchForm);
				if (e) return;
				if (data.code === 200) {
					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
			changeKey(type){
				console.log(type)
				if(type==3){
					return
				}
				this.typeLoading = true;
				if(type>3){
					this.getArticleAll();
				}else if(type<3){
					this.getTypeList(type)
				}
			},
			async getTypeList(type,num) {
				let [e, data] = await this.$api.getTypeList({type:type});
				this.typeLoading = false;
				if (e) return;
				if (data.code === 200) {
					this.typeList = data.data;
					if(!!num){
						this.showEdit = true;
					}else{
						this.editForm.value='';
						this.addTeacher.value='';
					}
				}
			},
			async getArticleAll(title,id) {
				let [e, data] = await this.$api.getArticleAllApi({
					title,
					id
				});
				this.typeLoading = false;
				if (e) return;
				if (data.code === 200) {
					if(!!id){
						this.oldSelect = data.data.data;
						this.articles = this.articles.concat(data.data.data);
						this.showEdit = true;
					}else{
						this.articles = data.data.data;
						this.editForm.value='';
						this.addTeacher.value='';
					}
				}
			},
			@delToast()
			async delUuserQA({id}) {
				let [e, data] = await this.$api.delBanner({id});
				if (e) return;
				if (data.code === 200) {
					this.getMessageType();
					this.$message.success('删除成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},

			//添加分类
			async addUserQA() {
				this.loading =true;
				let [e, data] = await this.$api.createBanner(this.addTeacher);
				if (e) return;
				if (data.code === 200) {
					// this.getTeacherList();
					this.loading =false;
					this.showAdd = false;
					this.getMessageType();
					this.$message.success('添加成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//类型编辑
			async saveUserQA() {
				this.editing = true;
				let [e, data] = await this.$api.saveBanner( this.editForm);
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.showEdit = false;
					this.getMessageType();
					this.$message.success('保存成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			}
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {
				}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	/deep/.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
